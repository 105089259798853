import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import DotStripSVG from '../../images/dot-strip.svg';

const DotStripStyling = createOwnUpStyle({
  backgroundImage: `url(${DotStripSVG})`,
  backgroundSize: 'cover',
  position: 'relative',
  height: 40,
  marginTop: 60,
  marginLeft: 'min(calc((1280px - 100vw) / 2), 0px)',
  marginRight: 'min(calc((1280px - 100vw) / 2), 0px)',
  width: '100vw',
  variants: {
    mediumAndDown: {
      marginLeft: 0,
      right: 0
    }
  }
});

export const DotStrip = createOwnUpComponent(OwnUpBox, DotStripStyling);
