import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const NPR = () => (
  <StaticImage
    src="../../../../images/press-npr.png"
    alt="National Public Radio (NPR)"
    placeholder="blurred"
    quality={100}
    width={210}
    objectFit="scale-down"
  />
);

const Forbes = () => (
  <StaticImage
    src="../../../../images/press-forbes.png"
    alt="Forbes"
    placeholder="blurred"
    quality={100}
    width={210}
    objectFit="scale-down"
  />
);

const YahooFinance = () => (
  <StaticImage
    src="../../../../images/press-yahoo-finance.png"
    alt="Yahoo Finance"
    placeholder="blurred"
    quality={100}
    width={210}
    objectFit="scale-down"
  />
);

const USNews = () => (
  <StaticImage
    src="../../../../images/press-us-news.png"
    alt="U.S. News"
    placeholder="blurred"
    quality={100}
    width={210}
    objectFit="scale-down"
  />
);

const Bloomberg = () => (
  <StaticImage
    src="../../../../images/press-bloomberg.png"
    alt="Bloomberg News"
    placeholder="blurred"
    quality={100}
    width={210}
    objectFit="scale-down"
  />
);

const USAToday = () => (
  <StaticImage
    src="../../../../images/press-usa-today.png"
    alt="USA Today"
    placeholder="blurred"
    quality={100}
    width={210}
    objectFit="scale-down"
  />
);

export const pressImages = [
  <NPR key="npr" />,
  <Forbes key="forbes" />,
  <YahooFinance key="yahoo-finance" />,
  <USNews key="us-news" />,
  <Bloomberg key="bloomberg" />,
  <USAToday key="usa-today" />
];
