import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpColumns
} from '@rategravity/own-up-component-library-legacy';
import React, { ReactNode } from 'react';
import { DotStrip } from '../dot-strip';
import { pressImages as images } from '../static-images/old/press';
import { LightHeader } from '../typography';

const columnProps = {
  count: {
    small: 2,
    medium: 3,
    large: 6
  },
  style: {
    padding: 32
  }
};

/**
 * Return images based on the graphQL query.
 */
export const QueriedPress = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <LightHeader>As featured in</LightHeader>
      <div style={{ width: '100%' }}>
        <OwnUpColumns {...columnProps}>{images}</OwnUpColumns>
      </div>
    </div>
  );
};

const PressWrapperStyle = createOwnUpStyle({
  width: '100%',
  marginTop: 50,
  variants: {
    mediumAndDown: {
      marginTop: 0
    }
  }
});

const PressWrapper = createOwnUpComponent('section', PressWrapperStyle);

/**
 * Container for the logos for publications.
 */
export const PressMentions = ({ children }: { children: ReactNode }) => {
  return (
    <PressWrapper aria-label="Own Up in the press">
      {children}
      <DotStrip />
    </PressWrapper>
  );
};
